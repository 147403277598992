import * as React from "react";
import { useEffect } from "react";
import { Link } from "gatsby";

import LogoIcon from "../assets/svg/logo.svg";
import ShopInterior from "../assets/img/shop-interior.jpeg";
import RestaurantInterior from "../assets/img/restaurant-interior.jpeg";
import MenuOrderFurniture from "../assets/img/menu-ordered-furniture.jpeg";
import Arrow from "../assets/svg/arrow.svg";

export const Header = ({ handleSidebarOpen }) => {
  useEffect(() => {
    window.onscroll = () => {
      const header = document.getElementById("header");
      if (window.pageYOffset <= 0) {
        header.style.backgroundColor = "transparent";
      } else {
        header.style.backgroundColor = "#081E30";
      }
    };
  });
  return (
    <header>
      <div
        id="header"
        className=" header fixed z-50 inset-x-0 top-0 flex items-center justify-between transition duration-500 lg:items-start"
      >
        {/* <!-- PCメニュー --> */}
        <div className=" flex items-center justify-between px-6 py-1.5 w-full md:px-10 lg:py-4 lg:px-16">
          <div className="z-50 lg:w-fit-content">
            <Link to="/">
              <img className="w-8 md:w-10" src={LogoIcon} alt="" />
            </Link>
          </div>
          <div id="menu" className="hidden justify-end pt-2 w-5/6 lg:flex">
            <div className="group z-20 mr-4 font-semibold xl:mr-8">
              <Link className="text-white text-sm tracking-wider" to="/quality">
                こだわり
              </Link>
              <div className=" mt-3 mx-auto w-full h-px bg-white rounded-full transform scale-x-0 group-hover:scale-x-100 transition duration-300"></div>
            </div>
            <div className="group mx-4 font-semibold xl:mx-8">
              <Link
                className="text-white text-sm tracking-wider group-hover:opacity-80"
                to="#"
              >
                サービス内容
              </Link>
              <div className="absolute z-10 inset-x-0 top-0 group-hover:block hidden pt-20">
                <div className="px-12 py-8 bg-primary-bg">
                  <div className="flex justify-center gap-x-8 mx-auto max-w-5xl">
                    <Link
                      className="w-1/5 text-white text-sm tracking-wider"
                      to="/shop-interior"
                    >
                      <div className="mb-2 overflow-hidden">
                        <img
                          className=" w-full h-40 object-cover transform hover:scale-110 duration-300"
                          src={ShopInterior}
                          alt=""
                        />
                      </div>
                      物販店舗
                    </Link>
                    <Link
                      className="w-1/5 text-white text-sm tracking-wider"
                      to="/restaurant-interior"
                    >
                      <div className="mb-2 overflow-hidden">
                        <img
                          className=" w-full h-40 object-cover transform hover:scale-110 duration-300"
                          src={RestaurantInterior}
                          alt=""
                        />
                      </div>
                      飲食店舗
                    </Link>
                    <Link
                      className="w-1/5 text-white text-sm tracking-wider"
                      to="/ordered-furniture"
                    >
                      <div className="mb-2 overflow-hidden">
                        <img
                          className=" w-full h-40 object-cover transform hover:scale-110 duration-300"
                          src={MenuOrderFurniture}
                          alt=""
                        />
                      </div>
                      別注家具
                    </Link>
                  </div>
                </div>
              </div>
              <img
                src={Arrow}
                alt=""
                className=" mt-1 mx-auto w-1.5 transform group-hover:-rotate-90 rotate-90 transition duration-300"
              />
            </div>
            <div className="group z-20 mx-4 font-semibold xl:mx-8">
              <Link className="text-white text-sm tracking-wider" to="/work">
                実例紹介
              </Link>
              <div className=" mt-3 mx-auto w-full h-px bg-white rounded-full transform scale-x-0 group-hover:scale-x-100 transition duration-300"></div>
            </div>
            <div className="group mx-4 font-semibold xl:mx-8">
              <Link className="text-white text-sm tracking-wider" to="/company">
                会社概要
              </Link>
              <div className=" mt-3 mx-auto w-full h-px bg-white rounded-full transform scale-x-0 group-hover:scale-x-100 transition duration-300"></div>
            </div>
            <div className="group mx-4 font-semibold xl:mx-8">
              <Link className="text-white text-sm tracking-wider" to="/career">
                採用情報
              </Link>
              <div
                className=" mt-3 mx-auto w-full h-px bg-white rounded-full transform scale-x-0 group-hover:scale-x-100 transition duration-300
                "
              ></div>
            </div>
            <div className="group ml-4 font-semibold xl:ml-8">
              <Link className="text-white text-sm tracking-wider" to="/contact">
                お問い合わせ
              </Link>
              <div className=" mt-3 mx-auto w-full h-px bg-white rounded-full transform scale-x-0 group-hover:scale-x-100 transition duration-300"></div>
            </div>
          </div>
          <button
            onClick={handleSidebarOpen}
            id="menuBtn"
            className="relative z-50 flex items-start flex-col justify-center w-8 h-8 cursor-pointer transition duration-500 ease-in-out lg:hidden"
          >
            <div
              id="menuBtnBurger"
              className=" transiton w-5 h-0.5 bg-white rounded-full duration-500 ease-in-out"
            />
          </button>
        </div>
        {/* <!-- モバイルメニュー --> */}
        <div
          id="mobileMenu"
          className=" absolute inset-x-0 top-0 pt-24 px-6 w-screen bg-primary-bg bg-opacity-0 invisible transition duration-500 md:px-10"
        >
          {/* <!-- メニュー  --> */}
          <ul
            id="menuList"
            className=" flex flex-col mb-12 opacity-0 pointer-events-none transition duration-500"
          >
            <li className="flex flex-col w-full text-center">
              <Link
                className=" flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                トップページ
              </Link>
            </li>
            <li className="flex flex-col w-full text-center">
              <Link
                className=" flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/quality"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                FINEのこだわり
              </Link>
            </li>
            <li className="flex flex-wrap justify-between w-full text-center">
              <Link
                className=" flex items-center my-3 w-3/4 text-left text-white text-sm tracking-widest"
                to="#"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                サービス内容
              </Link>
              <button className="btn-accordion relative">
                <span className="btn-minus"></span>
                <span className="btn-plus"></span>
              </button>
              <div className=" content-accordion-header flex flex-col mx-6 w-full max-h-0 overflow-hidden">
                <Link
                  className=" flex items-center mb-2 mt-4 text-left text-white text-sm"
                  to="/shop-interior"
                >
                  <img className="mr-4 w-1.5" src={Arrow} alt="" />
                  物販店舗
                </Link>
                <Link
                  className="flex items-center my-2 text-left text-white text-sm"
                  to="/restaurant-interior"
                >
                  <img className="mr-4 w-1.5" src={Arrow} alt="" />
                  飲食家具
                </Link>
                <Link
                  className="flex items-center my-2 text-left text-white text-sm"
                  to="/ordered-furniture"
                >
                  <img className="mr-4 w-1.5" src={Arrow} alt="" />
                  別注家具
                </Link>
              </div>
            </li>
            <li className="flex flex-col w-full text-center">
              <Link
                className=" flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/work"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                実例紹介
              </Link>
            </li>
            <li className="flex flex-col w-full text-center">
              <Link
                className=" flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/flow"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                仕事の流れ
              </Link>
            </li>
            <li className="flex flex-col w-full text-center">
              <Link
                className="flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/company"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                会社概要
              </Link>
            </li>
            <li className="flex flex-col w-full text-center">
              <Link
                className=" flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/career"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                採用情報
              </Link>
            </li>
            <li className="flex flex-col w-full text-center">
              <Link
                className=" flex items-center my-3 text-left text-white text-sm tracking-widest"
                to="/contact"
              >
                <img className="mr-4 w-1.5" src={Arrow} alt="" />
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
        {/* <!-- ページ上部に戻る --> */}
        <div
          id="toTop"
          className=" fixed bottom-4 right-4 opacity-0 transition duration-500 md:bottom-12 md:right-8 lg:right-10"
        >
          <Link
            to="#top"
            className=" flex items-center justify-center w-12 h-12 bg-primary-blue rounded-full"
          >
            <svg
              className="w-2 transform -rotate-90"
              viewBox="0 0 6 11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.843307 10.1134L5.90002 5.05672L0.843307 -1.90735e-06L2.47955e-05 0.842685L4.21465 5.05672L2.47955e-05 9.27075L0.843307 10.1134Z"
                fill="white"
              />
            </svg>
          </Link>
        </div>
      </div>
    </header>
  );
};
