import React, { useState } from "react";
import { Link } from "gatsby";

import MenuClose from "../assets/svg/close.svg";
import Arrow from "../assets/svg/arrow.svg";
import Plus from "../assets/svg/plus.svg";

export const Modal = ({ handleSidebarClose }) => {
  const grouped = [
    [
      { to: "/", title: "トップページ" },
      { to: "/quality", title: "FINEのこだわり" },
    ],
    {
      title: "サービス内容",
      links: [
        { to: "/shop-interior", title: "物販店舗" },
        { to: "/restaurant-interior", title: "飲食店舗" },
        { to: "/ordered-furniture", title: "別注家具" },
      ],
    },
    [
      { to: "/work", title: "実例紹介" },
      { to: "/company", title: "企業概要" },
      { to: "/career", title: "採用情報" },
      { to: "/contact", title: "お問い合わせ" },
    ],
  ];

  return (
    <div className="h-screen bg-primary-bg w-full">
      <div className="flex justify-end">
        <button onClick={handleSidebarClose} className="block pt-6 pr-8">
          <img className="w-4" src={MenuClose} alt="menu close" />
        </button>
      </div>
      <div className="mt-4 px-3">
        {grouped[0].map(({ to, title }, index) => (
          <div key={index} className="my-2">
            <Item to={to} title={title} />
          </div>
        ))}
        <Accordion title={grouped[1].title} links={grouped[1].links} />
        {grouped[2].map(({ to, title }, index) => (
          <div key={index} className="my-2">
            <Item to={to} title={title} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Item = ({ to, title }) => {
  return (
    <Link to={to} className="block">
      <div className="flex items-center p-3">
        <div className="mr-3">
          <img src={Arrow} alt="arrow" />
        </div>
        <div>
          <h3 className="text-white">{title}</h3>
        </div>
      </div>
    </Link>
  );
};

const Accordion = ({ title, links }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);
  return (
    <div>
      <button
        onClick={handleExpand}
        className="w-full pointer-cursor flex items-center justify-between p-3"
      >
        <div className="flex items-center">
          <div className="mr-3">
            <img
              src={Arrow}
              alt="arrow"
              style={{
                transition: "all 0.5 ease-in",
                transform: expanded ? `rotate(90deg)` : `rotate(0deg)`,
              }}
            />
          </div>
          <div>
            <h3 className="text-white">{title}</h3>
          </div>
        </div>
        <div className="pr-2">
          {expanded ? (
            <div className="w-4 h-px bg-white" />
          ) : (
            <h3 className="text-white">
              <img src={Plus} alt="plus" />
            </h3>
          )}
        </div>
      </button>
      {expanded ? (
        <div className="pt-1" style={{ paddingLeft: 45 }}>
          {links.map(({ to, title }) => (
            <Link to={to} className="block py-3">
              <div className="flex items-center p-3">
                <div className="mr-3">
                  <img src={Arrow} alt="arrow" />
                </div>
                <div>
                  <h3 className="text-white">{title}</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
};
