import * as React from 'react';
import { Link } from 'gatsby';

import CareerImg from '../assets/img/bg-career.jpeg';
import LOGO from '../assets/svg/logo.svg';
import CompanyImg from '../assets/img/bg-company.jpeg';
import FooterImg from '../assets/img/bg-footer.jpeg';
import FlowImg from '../assets/img/bg-flow.jpeg';
import ContactImg from '../assets/img/bg-contact.jpeg';

export const Footer = () => {
  return (
    <>
      <section>
        <div className="w-screen h-full bg-primary-bg">
          <div className=" flex flex-wrap mx-auto px-6 py-10 md:p-10 md:pb-14 lg:pb-32 lg:pt-20 lg:max-w-5xl">
            <div className="mb-4 w-full h-full md:pr-2 md:w-1/2 lg:mb-8 lg:pr-4">
              <Link
                to="/flow"
                style={{ backgroundImage: `url(${FlowImg})` }}
                className=" flex flex-col justify-center px-6 w-full h-32 bg-cover rounded lg:h-40"
              >
                <h2 className="text-white text-lg tracking-wider md:text-xl lg:text-3xl">
                  FLOW
                </h2>
                <span className="text-white text-xs md:text-sm">
                  施工の流れ
                </span>
              </Link>
            </div>
            <Link
              to="/company"
              className=" mb-4 w-full h-full transform md:pl-2 md:w-1/2 md:translate-y-8 lg:mb-8 lg:pl-4 lg:translate-y-16"
            >
              <div
                style={{ backgroundImage: `url(${CompanyImg})` }}
                className=" flex flex-col justify-center px-6 w-full h-32 bg-cover rounded lg:h-40"
              >
                <h2 className="text-white text-lg tracking-wider md:text-xl lg:text-3xl">
                  COMPANY
                </h2>
                <span className="text-white text-xs md:text-sm">会社概要</span>
              </div>
            </Link>
            <Link
              to="/career"
              className="mb-4 w-full h-full md:pr-2 md:w-1/2 lg:mb-8 lg:pr-4"
            >
              <div
                style={{ backgroundImage: `url(${CareerImg})` }}
                className=" flex flex-col justify-center px-6 w-full h-32 bg-cover rounded lg:h-40"
              >
                <h2 className="text-white text-lg tracking-wider md:text-xl lg:text-3xl">
                  CAREER
                </h2>
                <span className="text-white text-xs md:text-sm">採用情報</span>
              </div>
            </Link>
            <Link
              to="/contact"
              className=" mb-4 w-full h-full transform md:pl-2 md:w-1/2 md:translate-y-8 lg:pl-4 lg:translate-y-16"
            >
              <div
                style={{ backgroundImage: `url(${ContactImg})` }}
                className=" flex flex-col justify-center px-6 w-full h-32 bg-cover rounded lg:h-40"
              >
                <h2 className="text-white text-lg tracking-wider md:text-xl lg:text-3xl">
                  CONTACT
                </h2>
                <span className="text-white text-xs md:text-sm">
                  お問い合わせ
                </span>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <footer>
        <div className="relative w-full h-96 overflow-hidden md:h-104 xl:h-128">
          <div className=" flex flex-wrap items-center justify-between mx-auto p-6 md:p-10 lg:p-16 lg:max-w-5xl">
            <div className="mb-4">
              <div className="mb-2 w-full md:mb-4">
                <img className="w-8 md:w-10" src={LOGO} alt="" />
              </div>
              <div className="mb-2 w-full md:mb-4">
                <span className="mb-1 text-white text-sm tracking-wider md:text-base">
                  大阪本社
                </span>
                <span className="mb-1 text-white text-xs tracking-wider md:text-sm">
                  〒581-0041　大阪府八尾市北木ノ本3-36
                </span>
                <span className="text-white text-xs tracking-wider md:text-sm">
                  TEL
                  <a
                    className="mx-2 underline text-base md:text-lg"
                    href="tel:03-6450-7910"
                  >
                    072-993-8253
                  </a>
                </span>
              </div>
              <div className="mb-2 w-full md:mb-4">
                <span className="mb-1 text-white text-sm tracking-wider md:text-base">
                  東京事務所
                </span>
                <span className="mb-1 text-white text-xs tracking-wider md:text-sm">
                  〒154-0002 東京都世田谷区下馬1-34-12-B104
                </span>
                <span className="text-white text-xs tracking-wider md:text-sm">
                  TEL
                  <a
                    className="mx-2 underline text-base md:text-lg"
                    href="tel:03-6450-7910"
                  >
                    03-6450-7910
                  </a>
                </span>
              </div>
            </div>
            <div className="relative mx-auto w-fit-content lg:mx-0">
              <Link
                className=" btn-background-slide group flex items-center justify-center mx-auto py-2 w-48 bg-primary-blue md:py-4 md:w-64"
                to="/contact"
              >
                <span className=" z-10 group-hover:text-primary-blue text-white text-sm tracking-wider transition duration-300 ease-in-out md:text-base">
                  メールにてお問い合わせ
                </span>
              </Link>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${FooterImg})`,
              margin: -4,
              zIndex: -1,
            }}
            className="absolute inset-0 bg-cover bg-center blur-sm filter"
          ></div>
          <div>
            <svg
              className=" wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-primary-bg fill-current"
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
        <div className="bg-primary-bg">
          <div className="mx-auto p-6 md:p-10 lg:px-16 lg:max-w-5xl">
            <div className="hidden items-start justify-between mb-16 w-full lg:flex">
              <div className="flex flex-col">
                <Link className="text-white text-xs tracking-widest" to="/">
                  トップページ
                </Link>
              </div>
              <div className="flex flex-col">
                <Link
                  className="text-white text-xs tracking-widest"
                  to="/quality"
                >
                  FINEのこだわり
                </Link>
              </div>
              <div className="flex flex-col">
                {[
                  { title: 'サービス内容', to: '#' },
                  { title: '物販店舗', to: '/shop-interior' },
                  { title: '飲食店舗', to: '/restaurant-interior' },
                  { title: '別注家具', to: '/ordered-furniture' },
                ].map((el, index) => (
                  <Link
                    key={index}
                    className="mb-4 text-white text-xs tracking-widest"
                    to={el.to}
                  >
                    {el.title}
                  </Link>
                ))}
              </div>
              {[
                { title: '実例紹介', to: '/work' },
                { title: '仕事の流れ', to: '/flow' },
                { title: '企業概要', to: '/company' },
                { title: '採用情報', to: '/career' },
                { title: 'お問い合わせ', to: '/contact' },
              ].map((el, index) => {
                return (
                  <div key={index} className="flex flex-col">
                    <Link
                      to={el.to}
                      className="text-white text-xs tracking-widest"
                    >
                      {el.title}
                    </Link>
                  </div>
                );
              })}
            </div>
            <p className="text-center text-gray-400 text-2xs tracking-wider lg:text-xs">
              ©︎ 2021 FINE INC. Here is our
              <Link className="text-white underline" to="/privacy-policy">
                privacy policty
              </Link>
              .
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
