import * as React from "react";
import { useState } from "react";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { Modal } from "../components/modal";

export const Layout = ({ children }) => {
  const [showMenubar, setShowMenubar] = useState(false);
  return (
    <div className="h-full min-h-screen">
      <Header handleSidebarOpen={() => setShowMenubar(true)} />
      {children}
      <Footer />

      <div
        style={{
          transition: "all 0.4s ease-in-out",
          transform: showMenubar ? "translateX(0)" : "translateX(100%)",
        }}
        className={`h-full left-0 w-full fixed z-50 top-0`}
      >
        <Modal handleSidebarClose={() => setShowMenubar(false)} />
      </div>
    </div>
  );
};
